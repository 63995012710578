import Login from './pages/Login'
import Scanner from './pages/Scanner'
import { ApiProvider } from './providers/ApiProvider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AuthProvider } from './providers/AuthProvider'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Page from './routes/Page'
import Home from './pages/Home'
import ProtectedRoute from './routes/ProtectedRoute'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Index from './pages/Index'
import Products from './pages/Products'
import Policies from './pages/Policies'
import Licenses from './pages/Licenses'
import Account from './pages/Account'
import Machines from './pages/Machines'
import CreateProduct from './pages/CreateProduct'
import CreatePolicy from './pages/CreatePolicy'
import CreateLicense from './pages/CreateLicense'

const defaultTheme = createTheme()

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <ApiProvider>
          <AuthProvider>
            <ToastContainer />
            <Routes>
              <Route
                path={Page.index.path}
                element={<ProtectedRoute children={<Index />} />}
              />
              <Route
                path={Page.home.path}
                element={<ProtectedRoute children={<Home />} />}
              />
              <Route path={Page.login.path} element={<Login />} />
              <Route
                path={Page.scanner.path}
                element={<ProtectedRoute children={<Scanner />} />}
              />
              <Route
                path={Page.products.path}
                element={<ProtectedRoute children={<Products />} />}
              />
              <Route
                path={Page.createProduct.path}
                element={<ProtectedRoute children={<CreateProduct />} />}
              />
              <Route
                path={Page.policies.path}
                element={<ProtectedRoute children={<Policies />} />}
              />
              <Route
                path={Page.createPolicy.path}
                element={<ProtectedRoute children={<CreatePolicy />} />}
              />
              <Route
                path={Page.licenses.path}
                element={<ProtectedRoute children={<Licenses />} />}
              />
              <Route
                path={Page.createLicense.path}
                element={<ProtectedRoute children={<CreateLicense />} />}
              />
              <Route
                path={Page.machines.path}
                element={<ProtectedRoute children={<Machines />} />}
              />
              <Route
                path={Page.account.path}
                element={<ProtectedRoute children={<Account />} />}
              />
            </Routes>
          </AuthProvider>
        </ApiProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
