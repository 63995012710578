import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography
} from '@mui/material'
import MiniDrawer from '../components/MiniDrawer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useApi } from '../providers/ApiProvider'
import { useEffect, useState, useCallback } from 'react'
import { PolicyModel } from '../models/PolicyModel'
import Page from '../routes/Page'

const Content = () => {
  const { getPolicies } = useApi()
  const [policies, setPolicies] = useState<PolicyModel[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const fetchPolicies = useCallback(async () => {
    setLoading(true)
    const res = await getPolicies()
    setPolicies(res?.data || [])
    setLoading(false)
  }, [getPolicies])

  const onCreate = () => {
    window.location.href = Page.createPolicy.path
  }

  useEffect(() => {
    fetchPolicies()
  }, [fetchPolicies])

  return (
    <Container>
      <Typography variant="h4">Policies</Typography>
      <Box sx={{ m: 2 }} />
      <Button variant="contained" onClick={() => onCreate()}>
        Create
      </Button>
      <Box sx={{ m: 2 }} />
      {loading && (
        <div className="center">
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="right">Authentication Strategy</TableCell>
                <TableCell align="right">Max Machines</TableCell>
                <TableCell align="right">Created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {policies.map((policy) => (
                <TableRow
                  key={policy.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {policy.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {policy.attributes.name}
                  </TableCell>
                  <TableCell align="right">
                    {policy.attributes.authenticationStrategy}
                  </TableCell>
                  <TableCell align="right">
                    {policy.attributes.maxMachines}
                  </TableCell>
                  <TableCell align="right">
                    {policy.attributes.created}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  )
}

export default function Policies() {
  return <MiniDrawer children={<Content />} />
}
