import {
  Box,
  Button,
  Container,
  FormControl,
  TextField,
  Typography
} from '@mui/material'
import MiniDrawer from '../components/MiniDrawer'
import { useApi } from '../providers/ApiProvider'
import { toast } from 'react-toastify'
import Page from '../routes/Page'
import { LoadingButton } from '@mui/lab'
import { useRef, useState } from 'react'

const Content = () => {
  const { createProduct } = useApi()
  const [loading, setLoading] = useState(false)
  const refName = useRef<HTMLInputElement>(null)

  const onSubmit = async () => {
    try {
      const name = refName.current?.value
      if (!name) {
        toast.error('Name is required')
        return
      }
      setLoading(true)
      const product = await createProduct(name)
      setLoading(false)
      console.log(product)
      toast.success('Product created')
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }
  }

  const onBack = () => {
    window.history.back()
  }

  return (
    <Container>
      <Typography variant="h4">{Page.createProduct.title}</Typography>
      <Box sx={{ m: 2 }} />
      <FormControl fullWidth variant="outlined">
        <TextField
          inputRef={refName}
          required
          id="name"
          label="Name"
          variant="outlined"
        />
        <Box sx={{ m: 2 }} />
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => onSubmit()}
        >
          Submit
        </LoadingButton>
        <Box sx={{ m: 2 }} />
        <Button variant="contained" color="secondary" onClick={() => onBack()}>
          Back
        </Button>
      </FormControl>
    </Container>
  )
}

export default function CreateProduct() {
  return <MiniDrawer children={<Content />} />
}
