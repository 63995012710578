import React, {
  createContext,
  useContext,
  useRef,
  ReactNode,
  useEffect,
  useCallback,
  useState
} from 'react'
import {
  ActivateResponse,
  CheckoutResponse,
  LicensesResponse,
  MachinesResponse,
  PoliciesResponse,
  ProductsResponse,
  ValidateResponse
} from '../models/Responses'
import { Buffer } from 'buffer'
import axios from 'axios'
import util from 'util'
import { PolicyModel } from '../models/PolicyModel'
import { ProductModel } from '../models/ProductModel'
import { LicenseModel } from '../models/LicenseModel'

interface ApiContextType {
  token: string
  validate: (code: string) => Promise<ValidateResponse | null>
  activate: (
    licenseKey: string,
    licenseId: string,
    machineFingerprint: string
  ) => Promise<ActivateResponse | null>
  checkout: (
    machineId: string,
    machineFingerprint: string,
    licenseKey: string
  ) => Promise<CheckoutResponse | null>
  createProduct: (name: string) => Promise<ProductModel>
  getProducts: () => Promise<ProductsResponse | null>
  createPolicy: (name: string, productId: string) => Promise<PolicyModel>
  getPolicies: () => Promise<PoliciesResponse | null>
  createLicense: (
    policyId: string,
    name?: string | undefined,
    expiry?: Date | undefined
  ) => Promise<LicenseModel>
  getLicenses: () => Promise<LicensesResponse | null>
  getMachines: () => Promise<MachinesResponse | null>
}

const ApiContext = createContext<ApiContextType | undefined>(undefined)

const useApi = () => {
  const context = useContext(ApiContext)
  if (!context) {
    throw new Error('useApi must be used within an ApiProvider')
  }
  return context
}

interface Props {
  children: ReactNode
}

const ApiProvider: React.FC<Props> = ({ children }) => {
  const refAccountId = useRef<string>(process.env.REACT_APP_ACCOUNT_ID || '')
  const [token, setToken] = useState<string>('')

  // eslint-disable-next-line
  const host = `${process.env.REACT_APP_API_URL}/v1/accounts/${refAccountId.current}`
  // eslint-disable-next-line
  const headers = {
    'Accept': 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json'
  }

  const log = (object: any) => {
    console.log(
      util.inspect(object, { showHidden: true, depth: 2, colors: true })
    )
  }

  const getTokens = useCallback(async (): Promise<void> => {
    try {
      // check local storage
      const token = localStorage.getItem('token')
      if (token !== null && token !== '' && token !== undefined) {
        setToken(token)
        return
      }
      const username = process.env.REACT_APP_KEYGEN_USERNAME
      const password = process.env.REACT_APP_KEYGEN_PASSWORD

      const url = `${host}/tokens`
      const res = await fetch(url, {
        method: 'post',
        headers: {
          ...headers,
          Authorization: 'Basic ' + btoa(username + ':' + password)
        }
      })
      const data = await res.json()
      const newToken = data.data.attributes.token
      localStorage.setItem('token', newToken)
      setToken(newToken)
      console.log('get tokens successfully')
    } catch (e) {
      console.error(e)
    }
  }, [headers, host])

  const validate = async (code: string): Promise<ValidateResponse | null> => {
    const payload = Buffer.from(code, 'base64').toString()
    const codeData = JSON.parse(payload)
    console.log(codeData)
    const licenseKey = codeData.key
    const machineFingerprint = codeData.fingerprint

    // validate api
    const res = await fetch(
      `${host}/licenses/${encodeURIComponent(licenseKey)}/actions/validate`,
      {
        method: 'POST',
        headers: {
          'authorization': `License ${licenseKey}`,
          'content-type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify({
          meta: {
            scope: { fingerprint: machineFingerprint }
          }
        })
      }
    )

    const { meta, data, errors } = await res.json()
    if (errors) {
      console.error(errors)
    } else {
      console.log(meta)
      // console.log(data)
      const licenseId = data.id
      return { licenseKey, licenseId, machineFingerprint }
    }
    return null
  }

  const activate = async (
    licenseKey: string,
    licenseId: string,
    machineFingerprint: string
  ): Promise<ActivateResponse | null> => {
    try {
      const res = await fetch(`${host}/machines`, {
        method: 'POST',
        headers: {
          'authorization': `License ${licenseKey}`,
          'content-type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify({
          data: {
            type: 'machines',
            relationships: {
              license: {
                data: { type: 'licenses', id: licenseId }
              }
            },
            attributes: {
              fingerprint: machineFingerprint
            }
          }
        })
      })

      const { data, errors } = await res.json()
      if (errors) {
        console.log(errors)
      } else {
        // console.log(JSON.stringify(data, undefined, 4))
        return { machineId: data.id }
      }
    } catch (e) {
      console.error(e)
    }
    return null
  }

  const checkout = async (
    machineId: string,
    machineFingerprint: string,
    licenseKey: string
  ): Promise<CheckoutResponse | null> => {
    const res = await fetch(
      `${host}/machines/${encodeURIComponent(
        machineId || machineFingerprint
      )}/actions/check-out`,
      {
        method: 'POST',
        headers: {
          'authorization': `License ${licenseKey}`,
          'content-type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify({
          meta: {
            include: ['license'],
            encrypt: true
          }
        })
      }
    )
    const { data, errors } = await res.json()
    if (errors) {
      console.log(errors)
    } else {
      console.log(JSON.stringify(data, undefined, 8))
      return {
        certificate: data.attributes.certificate,
        machineId: data.relationships.machine.data.id
      }
    }
    return null
  }

  const createProduct = async (name: string): Promise<ProductModel> => {
    try {
      const url = `${host}/products`
      const res = await axios({
        method: 'post',
        url: url,
        headers: { ...headers, Authorization: `Bearer ${token}` },
        data: {
          data: {
            type: 'product',
            attributes: {
              name: name,
              distributionStrategy: 'LICENSED'
            }
          }
        }
      })
      log(res.data)
      return res.data.data
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  const getProducts = async (): Promise<ProductsResponse | null> => {
    try {
      if (token === '') return null
      const url = `${host}/products`
      const res = await axios({
        method: 'get',
        url: url,
        headers: { ...headers, Authorization: `Bearer ${token}` }
      })
      // log(res.data)
      return res.data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  const createPolicy = async (
    name: string,
    productId: string
  ): Promise<PolicyModel> => {
    try {
      const url = `${host}/policies`
      const res = await axios({
        method: 'post',
        url: url,
        headers: { ...headers, Authorization: `Bearer ${token}` },
        data: {
          data: {
            type: 'policy',
            attributes: {
              name: name,
              authenticationStrategy: 'LICENSE',
              scheme: 'ED25519_SIGN'
            },
            relationships: {
              product: {
                data: {
                  type: 'product',
                  id: productId
                }
              }
            }
          }
        }
      })
      log(res.data)
      return res.data.data
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  const getPolicies = async (): Promise<PoliciesResponse | null> => {
    try {
      if (token === '') return null
      const url = `${host}/policies`
      const res = await axios({
        method: 'get',
        url: url,
        headers: { ...headers, Authorization: `Bearer ${token}` }
      })
      log(res.data)
      return res.data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  const getMachines = async (): Promise<MachinesResponse | null> => {
    try {
      if (token === '') return null
      const url = `${host}/machines`
      const res = await axios({
        method: 'get',
        url: url,
        headers: { ...headers, Authorization: `Bearer ${token}` }
      })
      log(res.data)
      return res.data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  const createLicense = async (
    policyId: string,
    name?: string | undefined,
    expiry?: Date | undefined
  ): Promise<LicenseModel> => {
    try {
      const url = `${host}/licenses`
      const res = await axios({
        method: 'post',
        url: url,
        headers: { ...headers, Authorization: `Bearer ${token}` },
        data: {
          data: {
            type: 'license',
            attributes: {
              name: name,
              expiry: expiry
            },
            relationships: {
              policy: {
                data: {
                  type: 'policy',
                  id: policyId
                }
              }
            }
          }
        }
      })
      log(res.data)
      return res.data.data
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  const getLicenses = async (): Promise<LicensesResponse | null> => {
    try {
      if (token === '') return null
      const url = `${host}/licenses`
      const res = await axios({
        method: 'get',
        url: url,
        headers: { ...headers, Authorization: `Bearer ${token}` }
      })
      log(res.data)
      return res.data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  useEffect(() => {
    // console.log('ApiProvider is mounted')
    getTokens()
    // eslint-disable-next-line
  }, [])

  const apiContextValue: ApiContextType = {
    token,
    validate,
    activate,
    checkout,
    createProduct,
    getProducts,
    createPolicy,
    getPolicies,
    createLicense,
    getLicenses,
    getMachines
  }

  return (
    <ApiContext.Provider value={apiContextValue}>
      {children}
    </ApiContext.Provider>
  )
}

export { ApiProvider, useApi }
