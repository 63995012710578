import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography
} from '@mui/material'
import MiniDrawer from '../components/MiniDrawer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useApi } from '../providers/ApiProvider'
import { useEffect, useState, useCallback } from 'react'
import { ProductModel } from '../models/ProductModel'
import Page from '../routes/Page'

const Content = () => {
  const { getProducts } = useApi()
  const [products, setProducts] = useState<ProductModel[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const fetchProducts = useCallback(async () => {
    setLoading(true)
    const res = await getProducts()
    setProducts(res?.data || [])
    setLoading(false)
  }, [getProducts])

  const onCreate = () => {
    window.location.href = Page.createProduct.path
  }

  useEffect(() => {
    fetchProducts()
  }, [fetchProducts])

  return (
    <Container>
      <Typography variant="h4">{Page.products.title}</Typography>
      <Box sx={{ m: 2 }} />
      <Button variant="contained" onClick={() => onCreate()}>
        Create
      </Button>
      <Box sx={{ m: 2 }} />
      {loading && (
        <div className="center">
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell align="right">Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow
                  key={product.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {product.type}
                  </TableCell>
                  <TableCell align="right">{product.attributes.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  )
}

export default function Products() {
  return <MiniDrawer children={<Content />} />
}
